import { useState, useEffect } from 'react';
import { GetSampleRank} from '../HelperFunctions'
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Auth } from 'aws-amplify'; //Amplify, { Auth, Hub }
// import { Prompt } from 'react-router';
import SubjectChooserModal from "./SubjectChooserModal";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ENDPOINT_BASE = process.env.REACT_APP_API_URI;

const CaseInfoCard = (props) => {

  let history = useHistory();
  const [isPreprocessing, setIsPreprocessing] = useState(false);
  // const [shouldRemoveSubject, setShouldRemoveSubject] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentAnalysisId, setCurrentAnalysisId] = useState(null);

  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteModalShow = (inAnalysisId) => {setShowDeleteModal(true); setCurrentAnalysisId(inAnalysisId);}

  // useEffect(() => {
  //   document.title = "VaNex (" + props.caseID + ")"
  // }, []);

  // * example of an analysisElement
  // {
  //   "CASE_ID": "IGMCFM0090",
  //   "ANALYSIS_ID": "2023-08-11T19-52-31_SNV",
  //   "ANALYSIS_NAME": "IGMCFM0090",
  //   "ANALYSIS_NAME_SUFFIX": "2023-08-11T19-52-31",
  //   "ANALYSIS_TYPE": "SNV",
  //   "ANALYSIS_NOTE": "",
  //   "SOURCE_FILE_STATUS": 1,
  //   "JSON_URI": "s3://nch-igm-vanex/craniofacial/cases/CASE_ID=IGMCFM0090/2023-08-11T19-52-31_SNV.json.gz",
  //   "PARQUET_URI": "s3://nch-igm-research-cfm/IGMCFM_ANALYSES/2023/IGMCFM0090/Churchill/2023-08-08/varhouse/2023-08-09T10-14-57/parquet/IGMCFM0090.parquet",
  //   "V_REF": "GRCh38",
  //   "N_PARQUET_VARIANTS": 11229,
  //   "N_VARIANTS": 11229,
  //   "N_GENES": 6778,
  //   "N_SAMPLES": 3,
  //   "SAMPLES": [
  //       {
  //           "SAMPLE_ID": "IGMCFM_0090_001_S-2023-034251",
  //           "SAMPLE_TYPE": "Proband",
  //           "FILE_URI": "s3://nch-igm-research-cfm/IGMCFM_ANALYSES/2023/IGMCFM0090/Churchill/2023-08-08/alignment/BAMs/IGMCFM_0090_001_S-2023-034251.Recalibrated.bam"
  //       },
  //       {
  //           "SAMPLE_ID": "IGMCFM_0090_002_S-2023-034467",
  //           "SAMPLE_TYPE": "Mother",
  //           "FILE_URI": "s3://nch-igm-research-cfm/IGMCFM_ANALYSES/2023/IGMCFM0090/Churchill/2023-08-08/alignment/BAMs/IGMCFM_0090_002_S-2023-034467.Recalibrated.bam"
  //       },
  //       {
  //           "SAMPLE_ID": "IGMCFM_0090_003_S-2023-034465",
  //           "SAMPLE_TYPE": "Father",
  //           "FILE_URI": "s3://nch-igm-research-cfm/IGMCFM_ANALYSES/2023/IGMCFM0090/Churchill/2023-08-08/alignment/BAMs/IGMCFM_0090_003_S-2023-034465.Recalibrated.bam"
  //       }
  //   ]
  // }

  function preprocessCaseData(caseID, analysis, parquetURI){
    // if(!props.isAdmin){
    //   toast.error('Permission Denied.');
    //   return;
    // }
    
    const sampleids = !!analysis.SAMPLES ? analysis.SAMPLES.map(i => i.SAMPLE_ID).join("::") : "";

    toast.promise(
      new Promise(function (resolve, reject) {
        setIsPreprocessing(true);
        // let parquetURI_temp = parquetURI
        // if (analysis.ANALYSIS_TYPE === 'SV' && parquetURI.includes('varhouse') ){
        //   console.log(parquetURI.split('varhouse')[0]+'sv');
        //   parquetURI_temp = parquetURI.split('varhouse')[0]+'sv'
        // }
        // if (analysis.ANALYSIS_TYPE === 'CNV' && parquetURI.includes('varhouse') ){
        //   console.log(parquetURI.split('varhouse')[0]+'cnvloh');
        //   parquetURI_temp = parquetURI.split('varhouse')[0]+'cnvloh'
        // }
        Auth.currentSession().then(data => {
          let id_token = data.getIdToken().getJwtToken();
          // const url_to_fetch = ENDPOINT_BASE+`preprocess_case_data?caseid=CD200123&s3path=s3://nch-igm-research-rare-disease/2021/CD200123/churchill/2021-02-01/varhouse/2021-02-03T14-00-21/parquet/CD200123.parquet`;
          const url_to_fetch = `${ENDPOINT_BASE}preprocess_case_data?projectname=${props.project}&caseid=${caseID}&analysistype=${analysis.ANALYSIS_TYPE}&analysisid=${analysis.ANALYSIS_ID}&sampleids=${sampleids}&updatedb=true&s3path=${parquetURI}`;
          fetch(url_to_fetch, {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Authorization': id_token
            }
          })
          .then(result => result.json())
          .then(result_json => {
            props.loadVaNexDb();
            // showActiveCaseCardInfo(caseID);
            setIsPreprocessing(false);
            resolve(result_json["JobRunId"]);
            // resolve(JSON.stringify(result_json));
          }).catch(e => {
            setIsPreprocessing(false);
            // setErrorLog(["Failed to fetch data.", e]);
            reject(`Failed to fetch data. [${e.toString()}]`);
          } );
        });
      }),
      {
        loading: 'Saving...',
        success: (data) => <div className="d-flex flex-column" ><div><b>Spark Engine Ignited:</b></div><div>{data}</div></div>,
        error: (err) => `Error: ${err.toString()}`,
      },
      {
        success: {
          duration: 5000,
          icon: '🔥',
        },
      }
    );
  }

  function deleteAnalysis(caseID, analysisID){

    handleDeleteModalClose();
    let resultOK = false;
    toast.promise(
      new Promise(function (resolve, reject) {
        setIsPreprocessing(true);
        Auth.currentSession().then(data => {
          let id_token = data.getIdToken().getJwtToken();
          const url_to_send_to = `${ENDPOINT_BASE}delete_analysis?project=${props.project}&caseid=${caseID}&analysisid=${analysisID}`;
          fetch(url_to_send_to, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
              'Authorization': id_token
            }
          })
          .then(result => {
            resultOK = (!!result && result.ok)
            return(result.json())
          })
          .then(result_json => {
            if(resultOK){
              props.loadVaNexDb();
              // showActiveCaseCardInfo(caseID);
              setIsPreprocessing(false);
              resolve(result_json);
            } else {
              setIsPreprocessing(false);
              reject(`Failed to delete data: [${result_json}]`);
            }
          }).catch(e => {
            setIsPreprocessing(false);
            // setErrorLog(["Failed to fetch data.", e]);
            reject(`Failed to delete data. [${e.toString()}]`);
          } );
        });
      }),
      {
        loading: 'Deleting...',
        success: (data) => <div className="d-flex flex-column" ><div><b>Done!</b></div><div>{data}</div></div>,
        error: (err) => `Error: ${err.toString()}`,
      }
      // ,{
      //   success: {
      //     duration: 5000,
      //     icon: '✅',
      //   },
      //   error: {
      //     duration: 5000,
      //     icon: '❗',
      //   },
      // }
    );
  }

  function remove_mrphene_subject_id(){
    props.callbackLoadingMrPheneSubjectData('null');
  }

  // if(!( props.caseInfo && props.caseInfo.PRIMARY_PHENOTYPE))
  if( props.caseInfo && (props.caseInfo.ID === null || props.caseInfo.ID === undefined)){
    return(
      <div className="container py-3">
        <div className="d-flex">
          <div className="card-body">
            <h2 className="my-4">"{props.caseID}" record is corrupted. Please let the admin know!</h2>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container py-3">
      <div className="d-flex">
        <div className="w-50">
          <div className="card m-3 shadow">
            <div className="card-header bg-blue text-light d-flex flex-row">
              <h5 className="flex-grow-1 d-flex flex-row align-items-center justify-content-center p-0 m-0">
                <span className="mx-2">{( props.caseInfo.STAR === 1 ? <i className="fas fa-star text-info"></i> : props.caseInfo.STAR=== 2 ? <i className="fas fa-star green-light pe-2"></i> : (props.caseInfo.STAR=== 5 ? <i className="fas fa-star red-mid"></i> : <i className="far fa-star text-secondary"></i>))}</span>
                {/* <span className="mx-2">{props.caseInfo.AGE === null ? <i className="far fa-snowflake grey-light icon-with-outline"></i> : props.caseInfo.AGE <=100 ? <i className="fas fa-snowflake green-light icon-with-outline"></i> : props.caseInfo.AGE <= 180 ? <i className="fas fa-snowflake darker-orange icon-with-outline"></i> : props.caseInfo.AGE <= 195 ? <i className="fas fa-snowflake red-mid icon-with-outline"></i> :  <i className="fas fa-snowflake blue-lighter icon-with-outline"></i> }</span> */}
                {props.caseID}{(!!props.caseInfo.ALT_ID ? ` (${props.caseInfo.ALT_ID})` : '')}
              </h5>
              <button type="button" className="edit-btn btn btn-sm shadow-sm p-0 m-0 px-1" 
              onClick={ () => history.push(`/${props.projectBaseURL}/${props.caseID}/edit`)}
              ><i className="fas fa-edit"></i> Edit</button>
            </div>
            <div className="card-body d-flex flex-column">
              <div className="mb-2 pb-1 d-flex flex-row border-bottom border-1">
                <div className="d-flex flex-column flex-grow-1">
                  <div className="card-text text-center">Protocol</div>
                  <div className="card-text fw-bold text-center">{props.caseInfo.PROTOCOL || '-'}</div>
                </div>  
                <div className="d-flex flex-column flex-grow-1">
                  <div className="card-text text-center">Status</div>
                  <div className="card-text fw-bold text-center">{props.caseInfo.CASE_STATUS || '-'}</div>
                </div>  
                <div className="d-flex flex-column flex-grow-1">
                  <div className="card-text text-center">Study Type</div>
                  <div className="card-text fw-bold text-center">{props.caseInfo.STUDY_TYPE || '-'}</div>
                </div>  
                <div className="d-flex flex-column flex-grow-1">
                  <div className="card-text text-center">Outcome</div>
                  <div className="card-text fw-bold text-center">{props.caseInfo.CASE_OUTCOME || '-'}</div>
                </div>  
                <div className="d-flex flex-column flex-grow-1">
                  <div className="card-text text-center">Notified</div>
                  <div className="card-text fw-bold text-center">{props.caseInfo.NOTIFIED || '-'}</div>
                </div>  
                <div className="d-flex flex-column flex-grow-1">
                  <div className="card-text text-center">Enrollment</div>
                  <div className="card-text fw-bold text-center">{props.caseInfo.ENROLLMENT || '-'}</div>
                </div>  
              </div>
              <div className="my-2 card-text"><span className="card-title fs-6 fw-bold me-3">Candidates:</span>{( (!!props.caseInfo.CANDIDATES) && (props.caseInfo.CANDIDATES.length > 0) ? props.caseInfo.CANDIDATES.join(', ') : '-')}</div>
              <div className="my-2 card-text"><span className="card-title fs-6 fw-bold me-3">Tags:</span>{( (!!props.caseInfo.TAGS) && (props.caseInfo.TAGS.length > 0) ? props.caseInfo.TAGS.join(', ') : '-')}</div>
              <div className="my-3 mb-4 card-text" >
                <span className="card-title fs-6 fw-bold me-3">Note:</span>
                <div className="show-overflow" style={{maxHeight:'40vh'}}>
                {(props.caseInfo.CASE_NOTE!==undefined && props.caseInfo.CASE_NOTE!==null && props.caseInfo.CASE_NOTE.length > 0) ? props.caseInfo.CASE_NOTE.split('\n').map( (str, ind) => <p className="ms-3" key={ind}>{str}</p>) : '-'}</div>
              </div>
              <div className="my-2 card-text"><span className="card-title fs-6 fw-bold me-3">GeneMatcher:</span>{props.caseInfo.GENEMATCHER || '-'}</div>
              <div className="my-2 card-text"><span className="card-title fs-6 fw-bold me-3">Publication:</span>{props.caseInfo.PUBLICATION || '-'}</div>
              {/* <div className="my-3 card-text"><h5 className="card-title">Outcome:<span className="card-text text-muted fs-6 fw-normal">{props.caseInfo.CASE_OUTCOME}</span></h5></div> */}
              {/* <div className="my-3 mb-4 card-text"><h5 className="card-title">Note:<span className="card-text text-muted fs-6 fw-normal">{props.caseInfo.CASE_NOTE}</span></h5></div> */}
            </div>
            {/* <footer className="card-footer text-muted">Total INDIVIDUALS <cite title="Source Title">Source Title</cite></footer> */}
          </div>
          {/* # Index(['ID', 'CASE_ID', 'ALT_ID', 'PROTOCOL', 'PRIMARY_PHENOTYPE',
#        'CASE_STATUS', 'STUDY_TYPE', 'CASE_NOTE', 'CASE_OUTCOME', 'INDIVIDUALS',
#        'ANALYSES', 'MRPHENE_SUBJECT_ID', 'STAR', 'PRIORITY', 'CANDIDATES',
#        'TAGS', 'LINK_JIRA', 'LINK_CONFLUENCE', 'NOTIFIED', 'GENEMATCHER',
#        'PUBLICATION'],ENROLLMENT */}

          <div className="card m-3 shadow">
            <div className="card-header bg-blue text-light d-flex flex-row">
              <h5 className="flex-grow-1 d-flex flex-row align-items-center justify-content-center p-0 m-0">Mr. Phene</h5>
              {/* <button type="button" className="edit-btn btn btn-sm shadow-sm p-0 m-0 px-1" 
              onClick={ () => history.push(`/${props.projectBaseURL}/${props.caseID}/edit`)}
              ><i className="fas fa-edit"></i> Edit</button> */}
            </div>
            <div className="card-body d-flex flex-row">
              {(props.hasMrPheneProfile)
              ? 
                <div className="d-flex flex-grow-1 flex-row align-items-center justify-content-start w-100">
                  <div className="flex-grow-1"><span className="fs-6 fw-bold me-3">Subject ID:</span>{props.caseInfo['MRPHENE_SUBJECT_ID']}</div>
                  {/* <i className="fas fa-times-circle ms-3 fs-6 text-danger cursor-pointer" onClick={remove_mrphene_subject_id}></i> */}
                  {/* <button type="button" className="btn btn-sm fw-bold analysis-action-button bg-blue text-light shadow-sm no-white-space-wrap" onClick={remove_mrphene_subject_id}>
                    <i className="fas fa-trash"></i><span className="text-small fw-bold ms-2 p-0 ">Delete</span>
                  </button> */}
                  <Button variant="outline-danger" size="sm" className="shadow-sm no-white-space-wrap" onClick={remove_mrphene_subject_id}>
                    <i className="fas fa-trash"></i><span className="text-small fw-bold ms-2 p-0 ">Delete</span>
                  </Button>
                </div>
              :
                <div className="d-flex flex-grow-1 flex-row align-items-center justify-content-center">
                    <SubjectChooserModal callbackLoadingMrPheneSubjectData={props.callbackLoadingMrPheneSubjectData} />
                    {/* <div className="m-0 ms-1 p-0 px-1 tab-menu-button cursor-pointer" onClick={chooseMrPheneSubject}>
                      <i className="fas fa-id-badge fs-6"></i><span className="fs-6 fw-bold ms-1 p-0">Choose Subject</span>
                    </div> */}
                </div>
              }
            </div>
          </div>


          <div className="card m-3 shadow">
            <div className="card-header bg-blue text-light d-flex flex-row">
              <h5 className="flex-grow-1 d-flex flex-row align-items-center justify-content-center p-0 m-0">Phenotype</h5>
              <button type="button" className="edit-btn btn btn-sm shadow-sm p-0 m-0 px-1" 
              onClick={ () => history.push(`/${props.projectBaseURL}/${props.caseID}/edit`)}
              ><i className="fas fa-edit"></i> Edit</button>
            </div>
            <div className="card-body">
              <div className="mb-4">
                <h5 className="card-title">Primary Phenotype</h5>
                <p className="card-text">{props.caseInfo.PRIMARY_PHENOTYPE}</p>
              </div>
              { Object.values(props.caseInfo.INDIVIDUALS).sort( (elA,elB) => (GetSampleRank(elB.INDIVIDUAL_TYPE) - GetSampleRank(elA.INDIVIDUAL_TYPE)) ).map((element, index) => {
                return (
                  <div key={index} className="mb-3">
                    <h5 className="card-title">{element.INDIVIDUAL_TYPE}
                      <span className="text-muted fs-6 fw-normal"> ({element.SEX}{(element.AGE)? ', '+(element.AGE) : ''})</span>
                    </h5>
                    <p className="card-text">{(element.PHENOTYPE) 
                      ? 
                        (element.PHENOTYPE)
                      : 
                        (element.AFFECTED) ? "Affected" : "Unaffected"}
                    </p>
                  </div>
                ); }) 
              }
            </div>
          </div>

        </div>


        <div className="w-50">

        <div className="card m-3 shadow">
            <div className="card-body d-flex flex-row">
              <div className="d-flex flex-grow-1 flex-row align-items-center justify-content-center">
                {(!!props.caseInfo.LINK_JIRA) ?
                  <a href={props.caseInfo.LINK_JIRA} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt  me-1"></i>JIRA</a>
                : <span><i className="fas fa-external-link-alt  me-1"></i>JIRA</span>}
              </div>
              <div className="d-flex flex-grow-1 flex-row align-items-center justify-content-center">
                {(!!props.caseInfo.LINK_CONFLUENCE) ?
                  <a href={props.caseInfo.LINK_CONFLUENCE} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt me-1"></i>CONFLUENCE</a>
                  : <span><i className="fas fa-external-link-alt  me-1"></i>CONFLUENCE</span>}
              </div>
            </div>
          </div>

          <div className="card m-3 shadow" style={{minHeight:'30rem'}}>
            <div className="card-header bg-blue text-light d-flex flex-row align-items-center justify-content-center">
              <h5 className="flex-grow-1 text-center p-0 m-0">Analyses</h5>
            </div>
            <div className="card-body p-0 ">
              <div className="py-1 d-flex flex-row border-bottom border-1 border-primary">
                {/* <div className="m-1 ms-2 d-flex flex-column border border-1 border-secondary flex-grow-1"> */}
                <div className="d-flex flex-row align-items-center flex-grow-1  justify-content-center">
                  {/* Load: */}
                  <div className="ms-1 d-flex flex-column align-items-start justify-content-center">
                    <label className="fs-6 d-flex align-items-center justify-content-center" >
                      <input className="mx-1" type="checkbox" name="LoadMyNotes"
                        onChange={(e) => props.steLoadMyNotes(e.target.checked)}
                        checked={props.loadMyNotes} />
                        My Notes
                    </label>
                    <label className="fs-6 d-flex align-items-center justify-content-center" >
                      <input className="mx-1" type="checkbox" name="LoadExistingNotes" disabled={ !(props.project !== "training" || props.devOptions)}
                        onChange={(e) => {
                          if(!props.loadExistingNotes){
                            props.setIsNoteScopeAllAnalysis(true);
                            if(props.openCases.find(t => t[0].split('::')[0]===props.caseID) !== undefined )
                              props.setReloadCaseData(true);
                          }
                          props.setLoadExistingNotes(e.target.checked);
                        }}
                        checked={props.loadExistingNotes} />
                        All Notes
                    </label>
                  </div>
                </div>

                <div className={`d-flex flex-row flex-grow-1 align-items-center justify-content-center ${(!props.loadExistingNotes) ? ' text-muted' : ''}`}>
                  {/* Scope: */}
                  <div className="ms-1 d-flex flex-column align-items-start justify-content-center">
                    <label className="fs-6 d-flex align-items-center " >
                      <input className="mx-1" type="radio" name="SingleAnalysis"
                        disabled={!props.loadExistingNotes}
                        onChange={(e) => {
                          props.setIsNoteScopeAllAnalysis(!e.target.checked);
                        }}
                        checked={!props.isNoteScopeAllAnalysis} />
                        Single Analysis
                    </label>
                    <label className="fs-6 d-flex align-items-center " >
                      <input className="mx-1" type="radio" name="SingleAnalysis"
                      disabled={!props.loadExistingNotes}
                        onChange={(e) => {
                          props.setIsNoteScopeAllAnalysis(e.target.checked);
                        }}
                        checked={props.isNoteScopeAllAnalysis} />
                        All Analyses
                    </label>
                  </div>
                </div>


                <div className="d-flex flex-row align-items-center flex-grow-1  justify-content-center">
                  {/* more: */}
                  <div className="ms-1 d-flex flex-column align-items-start justify-content-center">
                    <label className={`mx-2 fs-6 flex-grow-1 d-flex align-items-center justify-content-center ${ (props.hasMrPheneProfile ? '' : 'text-muted') }`} >
                      <input className="mx-1" type="checkbox" name="reloadFromS3"
                        onChange={(e) => props.setLoadMrPheneSubjectData(e.target.checked)}
                        disabled={!props.hasMrPheneProfile}
                        checked={ (props.hasMrPheneProfile ? props.loadMrPheneSubjectData : false)}
                      />
                      Load Mr. Phene Data
                    </label>
                    <label className={`mx-2 fs-6 flex-grow-1 d-flex align-items-center justify-content-center ${ (props.openCases.find(t => t[0].split('::')[0]===props.caseID) === undefined) ? 'text-muted' : '' }`} >
                      <input className="mx-1" type="checkbox" name="reloadFromS3"
                        onChange={(e) => props.setReloadCaseData(e.target.checked)}
                        disabled={(props.openCases.find(t => t[0].split('::')[0]===props.caseID) === undefined)}
                        checked={props.reloadCaseData}
                      />
                      Reload Data from S3
                    </label>
                  </div>
                </div>

                {/* {(props.caseIsAlreadyOpen) ? */}
                {/* {(props.openCases.find(t => t[0].split('::')[0]===props.caseID) !== undefined ) ?
                <label className="mx-2 fs-6 flex-grow-1 d-flex align-items-center justify-content-center" >
                  <input className="mx-1" type="checkbox" name="reloadFromS3"
                    onChange={(e) => props.setReloadCaseData(e.target.checked)}
                    checked={props.reloadCaseData} />
                    Reload Data from S3
                </label>
                : null } */}

              </div>

              <div className="mt-2">

                <div className="fs-6 mx-4 show-overflow" style={{minHeight:'8rem',maxHeight:'65vh'}}>
                  {(Object.values(props.caseInfo.ANALYSES).length > 0) ? 
                    Object.values(props.caseInfo.ANALYSES).map( (analysisElement, analysisInd) => {

                      const AnalysisElementComponent = () => {

                        const [expandAnalysisInfoPanel, setExpandAnalysisInfoPanel] = useState(false);

                        const PreProcessingStatus = () => {
                          if(analysisElement.SOURCE_FILE_STATUS===undefined || analysisElement.SOURCE_FILE_STATUS===null)
                            return(<div className="mx-2">Invalid Code</div>);
                          else if (analysisElement.SOURCE_FILE_STATUS===1)
                            return(<div className="mx-2"><i className="fas fa-check-circle green-mid"></i></div>);
                            // return(<div className="mx-2"><i className="fas fa-check-double"></i></div>);
                            // return(<div className="mx-2"><i className="fas fa-clipboard-check"></i></div>);
                          else if (analysisElement.SOURCE_FILE_STATUS===-1)
                            return(<div className="mx-2"><i className="fas fa-times-circle text-danger"></i></div>);
                          else if (analysisElement.SOURCE_FILE_STATUS===0)
                          return(<div className="mx-2 text-center"><i className="fas fa-skiing text-primary"></i></div>);
                            // return(<div className="mx-2 text-center"><i className="fas fa-rocket text-primary"></i></div>);
                          else if (analysisElement.SOURCE_FILE_STATUS===2)
                            return(<div className="mx-2"><i className="fas fa-cogs text-primary"></i></div>);
                          else
                            return(<div className="mx-2">No-Status</div>);
                        }

                        const FilteredStatus = () => {
                          if(analysisElement.ANALYSIS_TYPE === 'SNV' || analysisElement.ANALYSIS_TYPE==="GermlineSNV" || analysisElement.ANALYSIS_TYPE==="SomaticSNV")
                            if(analysisElement?.N_PARQUET_VARIANTS === 0 || analysisElement?.N_VARIANTS === 0)
                              return(<div className="mx-2"><i className="fas fa-filter grey"></i></div>);
                            else if(analysisElement?.N_PARQUET_VARIANTS !== analysisElement?.N_VARIANTS)
                              return(<div className="mx-2"><i className="fas fa-filter darker-orange"></i></div>);
                            else 
                              return(<div className="mx-2"><i className="fas fa-filter green-mid"></i></div>);
                        }

                        const renderCondenseView = () => {

                          const actionButton = () => {
                            if(analysisElement.SOURCE_FILE_STATUS===undefined || analysisElement.SOURCE_FILE_STATUS===null)
                              return(<div className="mx-2">Invalid Code</div>);
                            else if (analysisElement.SOURCE_FILE_STATUS===1){
                              let buttonText = <><i className="fas fa-users-cog pe-1"></i>Open</>;
                              if(props.openCases.find(t => t[0]===`${props.caseID}::${analysisElement.ANALYSIS_ID}`) !== undefined ){
                                if(props.reloadCaseData)
                                  buttonText = <><i className="fas fa-redo pe-1"></i>Reload</> 
                                  // buttonText = <><span className="plus-icon-next-to-arrow"><i className="fas fa-cog fa-xs"></i></span><i className="fas fa-redo pe-3"></i>Reload</> 
                                else
                                  buttonText = <><i className="fas fa-share pe-1"></i>Revisit</> 
                              }
                              return(
                                <button type="button" className="analysis-action-button px-2 py-1 my-0 btn btn-sm fw-bold bg-blue text-light shadow-sm" 
                                  onClick={ () => props.switchToAnalysis(props.caseID, analysisElement)}>{buttonText}</button>
                              );
                            }
                            // * either ready to process or has failed 
                            else if (analysisElement.SOURCE_FILE_STATUS===0 || analysisElement.SOURCE_FILE_STATUS===-1)
                              return(
                                //  {/* <button type="button" className="px-5 btn btn-lg btn-primary shadow-sm"  disabled={isPreprocessing}
                                // onClick={ () => {setIsPreprocessing(true); props.preprocessCaseData(props.caseID, analysisElement.ANALYSIS_ID, analysisElement.PARQUET_URI); } }>Preprocess (Parquet <i className="fas fa-arrow-right"></i> JSON)</button> */}
                                // {/* <button type="button" className="px-5 btn btn-lg btn-primary shadow-sm" onClick={ () => props.preprocessCaseData(props.caseID, analysisElement.ANALYSIS_ID, analysisElement.PARQUET_URI)}>Preprocess (Parquet <i className="fas fa-arrow-right"></i> JSON)</button> */}
                                <button type="button" className="analysis-action-button px-2 py-1 my-0 btn btn-sm fw-bold bg-blue text-light shadow-sm"  disabled={(isPreprocessing) } //|| analysisElement.ANALYSIS_TYPE === 'SV'
                                  onClick={ () => preprocessCaseData(props.caseID, analysisElement, analysisElement.PARQUET_URI)}><i className="fas fa-cogs pe-1"></i>Preprocess</button>
                              );
                            else if (analysisElement.SOURCE_FILE_STATUS===2)
                              return(<div className="me-1 text-muted">Running...<i className="fas fa-sync analysis-edit-button" onClick={props.refreshAnalysisStatus}></i></div>);
                            else
                              return(<div className="mx-2">Invalid Code</div>);
                          }

                          return(
                            <div style={{position:'relative'}}>
                              <div className="analysis-list d-flex flex-row px-1 align-items-center justify-content-center"
                                onClick={() => setExpandAnalysisInfoPanel(prevState => !prevState)}>
                                {/* onClick={() => setSelectedAnalysis(analysisElement.ANALYSIS_ID)}> */}
                                <div className="d-flex flex-row ps-2 py-2 align-items-center justify-content-center">
                                  {/* <div style={{width:'1rem'}}> {(selectedAnalysis===analysisElement.ANALYSIS_ID)? <i className="fas fa-caret-right fa-lg pe-1"></i> : ' '}</div> */}
                                  <div style={{width:'1rem'}}> {(!!expandAnalysisInfoPanel)? <i className="fas fa-caret-down pe-1"></i> : <i className="fas fa-caret-right pe-1"></i>}</div>
                                  <div className="fw-bold">{analysisElement.ANALYSIS_TYPE}</div>
                                  <PreProcessingStatus/>
                                  <FilteredStatus/>
                                </div> 
                                <div className={(props.devOptions ) ? "flex-grow-1 align-items-center d-flex me-2 pe-5":"flex-grow-1 d-flex align-items-center justify-content-center me-2 pe-5"} style={{overflowWrap:'anywhere'}}>
                                  <i className="fas fa-edit analysis-edit-button me-1"
                                    onClick={ () => history.push(`/${props.projectBaseURL}/${props.caseID}/analysis/${analysisElement.ANALYSIS_ID}/edit`)}></i>
                                  {analysisElement.ANALYSIS_NAME}
                                </div>
                                {/* <div className="analysis-action-button" ><i className="fas fa-cogs pe-1"></i>Preprocess</div> */}
                                <div className="analysis-action-button-wrapper">{actionButton()}
                                  {(props.devOptions ) ? 
                                    <>
                                      <button type="button" className="analysis-action-button px-2 py-1 my-0 ms-2 btn btn-sm fw-bold bg-blue text-light shadow-sm" //  disabled={(isPreprocessing || analysisElement.ANALYSIS_TYPE === 'CNV' || analysisElement.ANALYSIS_TYPE === 'SV') }
                                      onClick={ () => preprocessCaseData(props.caseID, analysisElement, analysisElement.PARQUET_URI)}><i className="fas fa-cogs pe-1"></i>ReProcess</button>
                                      <button type="button" className="btn-danger  px-2 py-1 my-0 ms-2 btn btn-sm fw-bold text-light shadow-sm"  disabled={(isPreprocessing) }
                                      // onClick={ () => deleteAnalysis(props.caseID, analysisElement.ANALYSIS_ID)}
                                      onClick={ () => (!props.isAdmin ? toast.error('Permission Denied!') : handleDeleteModalShow(analysisElement.ANALYSIS_ID) )}
                                      ><i className="fas fa-trash-alt pe-1 "></i>Delete</button>
                                    </>
                                    : '' }
                                </div>
                              </div>
                            </div>
                          );
                        }

                        const renderAnalysisDetails = () => {
                          if (analysisElement.ANALYSIS_TYPE==="SNV" || analysisElement.ANALYSIS_TYPE==="SNV_Pheno"
                               || analysisElement.ANALYSIS_TYPE==="GermlineSNV" || analysisElement.ANALYSIS_TYPE==="SomaticSNV"){
                            return(
                              <div className="px-2 mb-3 mt-0 d-flex flex-column" style={{minHeight:'10rem'}}> 
                                <div className="bg-white mb-2 p-2">
                                  <div className="mb-2 d-flex flex-row border-bottom border-1">
                                    {/* <div>{analysisElement.N_PARQUET_VARIANTS}</div> */}
                                    <div className="d-flex flex-column flex-grow-1">
                                      <div className="card-text text-center">Variants - Parquet</div>
                                      <div className="card-text fw-bold text-center">{analysisElement.N_PARQUET_VARIANTS}</div>
                                    </div>  
                                    <div className="d-flex flex-column flex-grow-1">
                                      <div className="card-text text-center">Variants - VaNex</div>
                                      <div className="card-text fw-bold text-center">{analysisElement.N_VARIANTS}</div>
                                    </div>  
                                    <div className="d-flex flex-column flex-grow-1">
                                      <div className="card-text text-center">Genes</div>
                                      <div className="card-text fw-bold text-center">{analysisElement.N_GENES}</div>
                                    </div>
                                    <div className="d-flex flex-column flex-grow-1">
                                      <div className="card-text text-center">Reference</div>
                                      <div className="card-text fw-bold text-center">{analysisElement.V_REF}</div>
                                    </div>
                                  </div>
                                  {(!!analysisElement.JSON_URI) ? <div className=""><span className="fw-bold">Json File URI:</span> {analysisElement.JSON_URI}</div> : null}
                                  <div className=""><span className="fw-bold">Parquet File URI:</span> {analysisElement.PARQUET_URI}</div>
                                  <div className="mt-2"><span className="fw-bold">Note:</span> {analysisElement.ANALYSIS_NOTE}</div>
                                </div>
                                <div className="bg-white">
                                  <h6 className="text-center mt-2 ">{analysisElement.N_SAMPLES} Sample(s)</h6>
                                  <div className="show-overflow border-top border-1" style={{minHeight:'5rem',maxHeight:'9rem'}}>
                                    {(props.project !== "training" ? 
                                      Object.values(analysisElement.SAMPLES).map( (analysisSample, analysisSampleIndex) =>                     
                                        <div key={analysisSampleIndex} className="m-1 p-2 border-bottom border-1 d-flex flex-column">
                                          <div className="fs-6"><span className="fw-bold">{analysisSample.SAMPLE_TYPE}:</span> ({analysisSample.SAMPLE_ID})</div>
                                          {(!!analysisSample.FILE_URI) ?
                                            <div className="fs-6"><span className="fw-bold ms-3">BAM S3 URI:</span> {analysisSample.FILE_URI}</div>
                                          : null}
                                        </div>
                                      ) 
                                    : null)}
                                  </div>
                                </div>
                            </div>
                          );
                          } else {
                          // } else if (analysisElement.ANALYSIS_TYPE==="CNV"){
                            return(
                              <div className="px-2 mb-3 mt-0 d-flex flex-column" style={{minHeight:'10rem'}}> 
                                <div className="bg-white mb-2 p-2">
                                  {(!!analysisElement.JSON_URI) ? <div className=""><span className="fw-bold">Json File URI:</span> {analysisElement.JSON_URI}</div> : null}
                                  <div className=""><span className="fw-bold">Parquet File URI:</span> {analysisElement.PARQUET_URI}</div>
                                  <div className="mt-2"><span className="fw-bold">Note:</span> {analysisElement.ANALYSIS_NOTE}</div>
                                </div>
                                <div className="bg-white">
                                  <h6 className="text-center mt-2">{analysisElement.N_SAMPLES} Sample(s)</h6>
                                  <div className="show-overflow border-top border-1" style={{minHeight:'8rem',maxHeight:'5rem'}}>
                                    {Object.values(analysisElement.SAMPLES).map( (analysisSample, analysisSampleIndex) =>
                                      <div key={analysisSampleIndex} className="m-1 p-2 border-bottom border-1 d-flex flex-column">
                                        <div className="fs-6">{analysisSample.SAMPLE_TYPE}: ({analysisSample.SAMPLE_ID})</div>
                                        {(!!analysisSample.FILE_URI)?
                                        <div className="fs-6"><span className="fw-bold ms-3">S3 URI:</span> {analysisSample.FILE_URI}</div>
                                        : null }
                                        {/* {(analysisSample.SV_URI) ? <><span className="fw-bold ms-4">SV S3 URI:</span> {analysisSample.SV_URI}</>  : null} */}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          } 
                        }
                        return(
                          <div className="analysis-element mb-2">
                            {renderCondenseView()}
                            <div className={`show-overflow analysis-info-panel ${(expandAnalysisInfoPanel) ? '' : 'collapsed'}`}>
                              {renderAnalysisDetails()}
                            </div>
                          </div>
                        );
                      }
                      return(<AnalysisElementComponent key={analysisInd} />);
                    })
                    : <div className="flex-center mt-3"><h3>No Data</h3></div>}
                </div>

              </div>

            </div>
            <div className="card-footer text-center bg-white flex-center add-new-analysis-wrapper py-3">
                <div className="add-new-analysis flex-center" onClick={ () => history.push(`/${props.projectBaseURL}/${props.caseID}/analysis/add`)}><i className="fas fa-plus fa-2x"></i></div>
            </div>

          </div>

        </div>
      </div>
     
      <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete xx (xxx)?</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Are you sure you want to delete <b>{currentAnalysisId}</b> from <b>{props.caseID}</b>?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteModalClose}>
            Close
          </Button>
          <Button variant="danger" onClick={ () => deleteAnalysis(props.caseID, currentAnalysisId)} >
            <i className="fas fa-trash-alt pe-1 "></i>Delete
          </Button>
        </Modal.Footer>
      </Modal>
    
    </div>
  );
}

export default CaseInfoCard;